<template>
  <div class="user-season-schedules-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="customerSeasonSchedulesList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getInvoices"/>
  </div>
</template>

<script>
  import moment from 'moment-jalaali'
  import axios from 'axios'
  import {addComma} from "../../../../assets/js/functions";
  import {getSaleInvoicesPrice} from "../../../../http/requests/sales";
  import {getUser, getUserSeasonSchedules} from "../../../../http/requests/users/users";

  export default {
    name: 'seasonSchedulesList',
    metaInfo() {
      return {
        title: this.$t('users.seasonSchedules.title')
      }
    },
    data() {
      return {
        requestSent: false,
        options: {
          id: 'customerSeasonSchedulesListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'createdAt',
            i18n: 'users.seasonSchedules.table.header.createdAt',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true
          },
          {
            field: 'endDate',
            i18n: 'users.seasonSchedules.table.header.endDate',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 100,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
            // filterDefault: this.$t('sales.invoices.labels.fromNow')
          },
          {
            field: 'startDate',
            i18n: 'users.seasonSchedules.table.header.startDate',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
          },
          {
            field: 'quantity',
            i18n: 'users.seasonSchedules.table.header.quantity',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 90,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n:
                  'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
          },
          {
            field: 'creator',
            i18n: 'users.seasonSchedules.table.header.creator',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 130,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'rowNumber',
            i18n: 'users.seasonSchedules.table.header.row',
            align: 'center',
            width: '80px',
            minWidth: 80,
            footer: {}
          }
        ],
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        user: {
          id: 0,
          name: ''
        },
        actions: [
          {
            toolbar: [
              {
                id: {name: 'insertSeasonScheduleInvoice', query: {user: this.$route.params.userId}},
                type: 'link',
                i18n: '',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'season_scheduler.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              // {
              //   id: {name: 'saleInvoicesTrash'},
              //   type: 'link',
              //   icon: 'icon-trash',
              //   iconPack: 'feather',
              //   permission: 'invoice.delete'
              // },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getUserDefault()
      this.getInvoices()
    },
    methods: {
      getUserDefault () {
        if (this.$route.params.userId) {
          this.user.id = this.$route.params.userId
          getUser(this.user.id).then(response => {
            const user = response.data.data
            this.user.name = `${user.name || ''} ${user.family || ''}`
            this.$store.dispatch('setPageTitle', this.$t('users.seasonSchedules.dynamicTitle', {gender: user.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.$t('genderTypes.woman'), name: this.user.name}))
          })
        }
      },
      getInvoices() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (this.data.length < this.total_count || !this.total_count) {
              if (this.$refs.customerSeasonSchedulesList && this.data.length === 0) this.$refs.customerSeasonSchedulesList.loadMoreStatus = 'FirstLoad'
              else if (this.$refs.customerSeasonSchedulesList && this.data.length > 0) this.$refs.customerSeasonSchedulesList.loadMoreStatus = 'Loading'

              getUserSeasonSchedules(this.$route.params.userId, this.page, this.filters, this.sorts).then((response) => {
                const invoices = response.data

                invoices.data.forEach((invoice) => {
                  this.data.push({
                    route: {name: 'seasonSchedule', params: {id: invoice.id}},
                    rowNumber: this.data.length + 1,
                    id: invoice.id,
                    createdAt: invoice.created_at.split(' ')[0],
                    quantity: invoice.invoices_count,
                    startDate: invoice.started_at,
                    endDate: invoice.ended_at,
                    user: `${invoice.user.name} ${invoice.user.family}`,
                    creator: invoice.creator ? `${invoice.creator.name || ''} ${invoice.creator.family || ''}` : '-',
                  })
                })

                this.total_count = invoices.pagination.total
                this.page = invoices.pagination.current_page + 1

                if (response.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('rowNumber')
                  this.columnsLabel[row_index].footer.value = response.data.pagination.total
                }

                if (this.$refs.customerSeasonSchedulesList) this.$refs.customerSeasonSchedulesList.loadMoreStatus = ''

                this.requestSent = false
              }).catch((error) => {
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else if (this.$refs.customerSeasonSchedulesList) this.$refs.customerSeasonSchedulesList.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
            } else {
              this.requestSent = false
            }
          }, 400)
        }
      },
      setFilter(filters) {
        let filters_list = []
        const attributes = []
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'id':
              if (filters[key].search !== '') filters_list.push(`invoiceNumber=${filters[key].search}`)
              break

            case 'receivedId':
              if (filters[key].search !== '') filters_list.push(`receivedId=${filters[key].search}`)
              break

            case 'creator':
              let search = filters[key].search
              if (search === 'سیستم' || search === 'سیستمی') {
                search = 'system'
              }
              if (filters[key].search !== '') filters_list.push(`creator=${ search}`)
              break

            case 'user':
              if (filters[key].search !== '') filters_list.push(`user=${filters[key].search}`)
              break

            case 'quantity':
              if (filters[key].search !== '') filters_list.push(`invoices_count=${filters[key].search},${filters[key].type.id}`)
              break

            case 'startDate':
              if (filters[key].search.length > 0) filters_list.push(`started_at=${filters[key].search.join('_')}`)
              break

            case 'endDate':
              if (filters[key].search.length > 0) filters_list.push(`ended_at=${filters[key].search.join('_')}`)
              break

            case 'createdAt':
              if (filters[key].search.length > 0) filters_list.push(`created_at=${filters[key].search.join('_')}`)
              break
          }
        })
        filters_list = [filters_list, ...attributes]

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.getInvoices()
      },
      setSort(sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'creator':
              sorts_list.push(`order[0]=creator,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'quantity':
              sorts_list.push(`order[0]=invoices_count,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'startDate':
              sorts_list.push(`order[0]=started_at,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'endDate':
              sorts_list.push(`order[0]=ended_at,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'createdAt':
              sorts_list.push(`order[0]=created_at,${sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.getInvoices()
      }
    }
  }
</script>

<style scoped>

</style>
